import React from 'react';
import { useState } from 'react';
import { Container, Carousel, Row, Col, Button } from "react-bootstrap";
import '../styles/BannerCarousel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import banner1 from "./images/banner/banner1.jpg"
import banner2 from "./images/banner/banner2.jpg"
import Slider from "react-slick";
const HeaderBody = () => {

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:false,
      
      };

    return (
      <div className="banner-slider"> 
        <Slider {...settings}>
          <div>
            <img src={banner1} alt="Banner 1" />
          </div>
          <div>
            <img src={banner2} alt="Banner 2" />
          </div>
       
        </Slider>
      </div>
    )
}

export default HeaderBody;