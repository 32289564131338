import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Topdeals.css';
import topdealfree from "./images/topDeals/Top Deals FreeImg.svg";
import topdeal10 from "./images/topDeals/Top Deals Under10Img.svg";
import topdeal5 from "./images/topDeals/Top Deals Under5Img.svg";
import topdealPersonalCare from "./images/topDeals/Top Deals PersonalCareImg.svg";
import Slider from "react-slick";

const TopDeals = () => {
  const navigate = useNavigate();

  const settings = {
    infinite: true, 
    speed: 500, 
    slidesToShow: 4, // Show 4 images in a row on web
    slidesToScroll: 1, // Scroll one image at a time
    autoplay: true, // Enable autoplay
    autoplaySpeed: 15000, 
    arrows: false, // Disable arrows
    dots: false, // Disable dots
    responsive: [
      {
        breakpoint: 768, // At 768px or less (common mobile breakpoint)
        settings: {
          slidesToShow: 2, // Show 2 images in a row
          slidesToScroll: 2, // Scroll two images at a time
          rows: 2, // Show 2 rows
        },
      },
    ],
  };

  const handleClick = (key) => {
    navigate(`/TopDeals?key=${key}`);
  };

  return (
    <div className="container top-deals">
      <h1>Top deals</h1>
      <div className="top-deals-container">
        <Slider {...settings}>
        <div className="deal-item" onClick={() => handleClick('1')}>
            <img src={topdeal10} alt="Under $10" />
          </div>
          <div className="deal-item" onClick={() => handleClick('2')}>
            <img src={topdealfree} alt="Free" />
          </div>
          <div className="deal-item" onClick={() => handleClick('3')}>
            <img src={topdeal5} alt="Under $5" />
          </div>
          <div className="deal-item" onClick={() => handleClick('4')}>
            <img src={topdealPersonalCare} alt="Personal Care" />
          </div>
          {/* <div className="deal-item" onClick={() => handleClick('5')}>
            <img src={sandwich} alt="Deal 5" />
          </div> */}
        </Slider>
      </div>
    </div>
  );
};

export default TopDeals;
