import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MerchantSkeletonCardDeck from "../components/MerchantSkeletonCardDeck";
import HeaderBody from "../components/HeaderBody";
import TopDeals from '../components/TopDeals';
import HowToClaim from '../components/HowToClaim';
import Footer from '../components/Footer';
import CouponSlider from '../components/CouponSlider';
import MerchantSlider from '../components/MerchantSlider';
import { useAuth } from "../provider/authProvider";

const Homepage = ({ coupons, merchants, isLoading, isError }) => {
  //const { locErrorMessage } = useLocation();

  if (isError) {
    return <div>Error loading data. Please try again later.</div>;
  }

  return (
    <div>
      {isLoading && <MerchantSkeletonCardDeck />}
      {/*{locErrorMessage && <div className="error-message">{locErrorMessage}</div>}*/}
      <HeaderBody />
      <TopDeals />
      <CouponSlider coupons={coupons} />
      <MerchantSlider merchants={merchants} />
      <HowToClaim />
      <Footer />
    </div>
  );
};

export default Homepage;
