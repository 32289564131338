import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getMenuUrl } from "../utils/HelperFunctions";
//import '../styles/MerchantCard.css'; //not needed, all the styles used are from parent component
import dummyimg from "../components/images/default/merchantFallback.svg"
import location from "../components/images/location.svg"

const MerchantCard = ({ merchant, index }) => {
    // URL based on run environment
    const apiUrl = process.env.REACT_APP_apiUrl;

    const [imageUrl, setImageUrl] = useState(dummyimg); // Initial image as fallback
    const [isLoading, setIsLoading] = useState(true);
    const bullet = " \u2022 ";

    /**
     * Fetches the presigned URL for the image associated with the coupon.
     */
    const fetchImage = async (objectKey) => {
        try {
        const response = await axios.get(
            `${apiUrl}/GeneratePresignedUrl`,
            {
            params: { objectKey },
            }
        );
        setImageUrl(response.data); // Set the fetched image URL
        } catch (error) {
        console.error("Error fetching image:", error);
        setImageUrl(dummyimg); // Fallback in case of an error
        } finally {
        setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchImage("MerchantLogos/"+merchant.imgName); // Trigger the image fetch on component mount
    }, [merchant.merchantID]);

    return (
        <div>
            <Link to={`/${getMenuUrl(merchant.merchantID)}`} style={{ textDecoration: "none", color: "inherit" }}>
                <Card key={index} className="merchant-card">
                    <Card.Img variant="top" src={imageUrl} className="card-logo"/>
                    <Card.Body className='text-center'>
                        <Card.Title className="card-text">{merchant.merchantName}</Card.Title>
                        <Card.Text>
                            <div className="card-center">
                                <div className="offer-count">{merchant.merchantType}{bullet}
                                    {[...merchant.subType1, ...merchant.subType2].join(bullet)}</div>
                            </div>
                        </Card.Text>
                        <div className="offer-count">{merchant.couponCount} Coupons Available</div>
                        {merchant.distanceToMerchant !== "9999.99 KM" && (
                        <div className="location card-location">
                            <span>
                                <img src={location} alt="location" /> 
                            </span> {merchant.distanceToMerchant}
                        </div>
                        )}
                        <Card.Link href={merchant.link} className='view-offer'>View More</Card.Link>
                    </Card.Body>
                </Card>
            </Link>
        </div>
    )
}


export default MerchantCard;

