import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from "../provider/authProvider";
import Slider from 'react-slick';

import WalletCardDeck from '../components/WalletCardDeck';
import SearchResultCard from '../components/SearchResultCard';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import "../styles/SearchResults.css";

const TopDealsPage = () => {
    // URL based on run environment
    const apiUrl = process.env.REACT_APP_apiUrl;
    const { token } = useAuth(); // to get email
    const email = token;
    const heading = "Top Deals";

    const [errorMessage, setErrorMessage] = useState('');
    const [deals, setDeals] = useState([]);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const key = query.get('key');
    

    useEffect(() => {
        const fetchDeals = async () => {
        try {
            const response = await axios.get(`${apiUrl}/GetTopDeals`, {
                params: {
                    email: email,  // Assuming userEmail is defined elsewhere in your code
                    key: key
                }
            });            
            setDeals(response.data);
        } catch (error) {
            console.error('Error fetching top deals:', error);
        }
        };

        if (key) {
        fetchDeals();
        }
    }, [key]);

    return (
        <div>
          <Container className="mx-auto wallet-details-wrap">
            <Row className="align-items-top mb-5">
                <Col className="p-0">
                    {errorMessage ? (
                        <Alert variant="danger">{errorMessage}</Alert>
                    ) : (
                        <>
                            {deals.length === 0 ? (
                                <Alert variant="info">Sorry, there are no qualifying coupons near your location.</Alert>
                            ) : (
                                <WalletCardDeck heading={heading}>
                                    {deals.map(coupon => (
                                        <SearchResultCard key={coupon.couponId} data={coupon} type="Coupon"/>
                                    ))}
                                </WalletCardDeck>
                            )}
                        </>
                    )}
                </Col>
            </Row>
          </Container>
        </div>
    );
};

export default TopDealsPage;
