import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from "../provider/authProvider";
import Slider from 'react-slick';

import WalletCardDeck from '../components/WalletCardDeck';
import SearchResultCard from '../components/SearchResultCard';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import "../styles/SearchResults.css";

const SearchResultsPage = () => {

    const heading = "Coupons & Merchants near you";

    const [errorMessage, setErrorMessage] = useState('');
    const location = useLocation();
    const { searchResults } = location.state || { searchResults: [] }; // Handle case where state might be undefined

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [searchResults]);

    return (
        <div>
          <Container className="mx-auto wallet-details-wrap">
            <Row className="align-items-top mb-5">
                <Col className="p-0">
                    {errorMessage ? (
                        <Alert variant="danger">{errorMessage}</Alert>
                    ) : (
                        <>
                            {searchResults.length === 0 ? (
                                <Alert variant="info">Sorry, there are no coupons or merchants near your location.</Alert>
                            ) : (
                                <WalletCardDeck heading={heading}>
                                    {searchResults.map(result => (
                                        <SearchResultCard 
                                            key={result.id} 
                                            data={result.type === 'Coupon' ? result.coupon : result.merchant}
                                            type={result.type}/>
                                    ))}
                                </WalletCardDeck>
                            )}
                        </>
                    )}
                </Col>
            </Row>
          </Container>
        </div>
    );
};

export default SearchResultsPage;
