import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import { getMenuUrl, getCouponUrl } from "../utils/HelperFunctions";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import logo from "./images/banner/coupen-logo-new.png";
import search from "./images/search.svg";
import wallet from "./images/WalletImg.svg"
import '../styles/Navbar.css';

const AppNavbar = ({token}) => {
  const apiUrl = process.env.REACT_APP_apiUrl;
  const [searchResults, setSearchResults] = useState([]);
  const [resultsWithNavigation, setResultsWithNavigation] = useState([]);
  const navigationItem = { id: "navigation", name: "View all results", isNavigation: true };
  const [selectedSearchResult, setSelectedSearchResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true); // New state to manage the hamburger menu collapse
  const placeholders = ["Search merchants", "Find coupons", "Discover deals", "Explore new merchants"];
  const navigate = useNavigate();
  // Ref to the nav element
  const navRef = useRef(null); 
  // Ref to typeahead
  const typeaheadRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [placeholders.length]);

  const fetchSearchResults = async (str) => {
    setIsLoading(true);
    setError(null);
    const location = localStorage.getItem('previousLocation');
    let latitude = null; 
    let longitude = null; 
    if (location) { 
      const parsedLocation = JSON.parse(location); 
      latitude = parsedLocation.latitude || null; 
      longitude = parsedLocation.longitude || null;
    }
    try {
      const response = await axios.get(`${apiUrl}/Search`, {
          params: {
              key: str,
              latitude: latitude,
              longitude: longitude
          }
      });

      const searchResults = response.data;

      // Combine the filtered results with the navigation item
      const filteredResults = searchResults.slice(0, 5);
      setResultsWithNavigation([...filteredResults, navigationItem]);
      setSearchResults(searchResults);
    } catch (error) {
      console.error('Error fetching search results:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  function clearTypeahead() {
    setSelectedSearchResult([]);
    setTimeout(() => { 
      if (typeaheadRef.current) { 
        typeaheadRef.current.blur(); // Remove focus from the Typeahead input 
      } 
    }, 0);
  }

  // Custom filter to prevent 'View all results' from being filtered out
  const customFilter = (option, props) => { 
    if (option.isNavigation) { 
      return true; 
    } 
    const query = props.text.toLowerCase(); 
    return option.name.toLowerCase().includes(query); 
  };

  const handleSelect = (selected) => {
    setSelectedSearchResult(selected);
    if (selected) {
      if(selected.type === "Coupon") {
        navigate(`/${getCouponUrl(selected.id)}`);
      }
      else {
        navigate(`/${getMenuUrl(selected.id)}`);
      }
      // Close the menu after selecting a merchant
      setIsNavCollapsed(true);
      clearTypeahead();
    }
  }

  function handleNavigateToSearchPage() { 
    navigate('/search', { state: { searchResults } });
    clearTypeahead();
  }

  const handleSearchChange = (text) => {
    if (text && text.length >= 3) {
      fetchSearchResults(text);
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && e.target.value.length >= 3) {
      handleNavigateToSearchPage();
    }
  } 

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed); // Toggle the menu collapse

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setIsNavCollapsed(true); // Close the menu if clicked outside
    }
  };
 
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className='nav-mbl-wrapper' ref={navRef}>
      <nav className="navbar navbar-expand-xl navbar-light fixed-top">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="Logo" className='Nav-bar-logo-section' />
          </a>
          <div className="ms-auto d-flex align-items-center">
            <Button className="py-0 mr-1 bg-transparent border-0 shadow-none wallet-icon-small" as={Link} to="/Wallet">
              <img src={wallet} alt="View wallet" style={{ width: '40px', height: '40px' }} />
            </Button>
            <button
              className="navbar-toggler"
              type="button"
              onClick={handleNavCollapse} // Toggle the menu on click
              aria-controls="navbarNav"
              aria-expanded={!isNavCollapsed}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <Button className="py-0 mr-1 bg-transparent border-0 shadow-none wallet-icon-large" as={Link} to="/Wallet">
                <img src={wallet} alt="View wallet" style={{ width: '40px', height: '40px' }} />
              </Button>
              {/*<li className="nav-item" onClick={handleNavCollapse}>
                <Link to="/" className="nav-link">Account</Link>
              </li>
              <li className="nav-item" onClick={handleNavCollapse}>
                <Link to="/" className="nav-link">Orders</Link>
              </li>   to be used later*/}
              {!token && (<li className="nav-item nav-item-row" onClick={handleNavCollapse}>
                <a className="btn btn-primary login" href="Login/">Log In</a>
              </li>)}
              {!token && (<li className="nav-item nav-item-row" onClick={handleNavCollapse}>
                <a className="btn btn-outline-primary signup" href="Signup/">Sign Up</a>
              </li>)}
              {token && (<li className="nav-item nav-item-row" onClick={handleNavCollapse}>
                <a className="btn btn-outline-primary signup" href="Signup/">Log Out</a>
              </li>)}
            </ul>
          </div>
        </div>
      </nav>
      <div className="search-icon">
        <div className="search-box-container">
          <form className="d-flex">
            <div className="input-group w-100">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <img src={search} alt="Search" width="20" height="20" />
                </span>
              </div>
              <Typeahead
                id="custom-typeahead"
                labelKey="name"
                className="custom-form-control custom-search-input"
                options={resultsWithNavigation}
                selected={selectedSearchResult}
                filterBy={customFilter}
                onChange={(selected) => { 
                  if (selected[0]?.isNavigation) { 
                    handleNavigateToSearchPage(); 
                  } 
                  else { 
                    handleSelect(selected[0]);
                  } 
                }}
                placeholder={placeholders[placeholderIndex]}
                onInputChange={(text) => handleSearchChange(text)}
                onKeyDown={handleKeyPress}
                minLength={3}
                ref={typeaheadRef}
              />
              {isLoading && (
                <div className="loading-indicator">Loading...</div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AppNavbar;
