import React from "react";
import '../styles/CoupenCard.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CuponCard from "./CouponCard";
import {Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


const CouponSlider = ({ coupons }) => {
  
  const navigate = useNavigate();

  const settings = {
    arrows: true,
    slidesToShow: 4,
    infinite: true,
    swipeToSlide: true,
    speed: 300,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          adaptiveHeight: false,
        },
      },
    ],
  };

  const handleViewAllClick = () => { 
    navigate('/view-all-coupons', { state: { coupons } }); 
  };

  return (
    <div className="container slider-item test-slider">
      <h1 className="near-by-item-text">Nearby 
        <Button className='m-0 p-0' variant="link" style={{color: "#e80d0d"}} onClick={handleViewAllClick}>View All</Button>
      </h1>
      {coupons.length === 0 ? (
        <div className="no-coupons-message">No Coupons nearby</div>
      ) : (
        <Slider {...settings} className="pb-5">
          {coupons.map(coupon => (
            <CuponCard key={coupon.couponID} coupon={coupon} />
          ))}
        </Slider>
      )}
    </div>
  );
};

export default CouponSlider;
