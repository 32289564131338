import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { getCouponUrl, getMenuUrl } from "../utils/HelperFunctions";

import dummyimg from "../components/images/default/couponFallback.png"
import location from "../components/images/location.svg"
import restaurent from "../components/images/rest-icon.svg"

import '../styles/SearchResults.css'

function SearchResultCard({ data, type }) {
    // URL based on run environment
    const apiUrl = process.env.REACT_APP_apiUrl;
    
    const navigate = useNavigate();
    const [imageUrl, setImageUrl] = useState(dummyimg); // Initial image as fallback
    const [isLoading, setIsLoading] = useState(true);
    const bullet = " \u2022 ";

    /**
     * Fetches the presigned URL for the image associated with the coupon.
     */
    const fetchCouponImage = async (objectKey) => {
        try {
        const response = await axios.get(
            `${apiUrl}/GeneratePresignedUrl`,
            {
            params: { objectKey },
            }
        );
        setImageUrl(response.data); // Set the fetched image URL
        } catch (error) {
        console.error("Error fetching image:", error);
        setImageUrl(dummyimg); // Fallback in case of an error
        } finally {
        setIsLoading(false);
        }
    };

    /**
     * Fetches the presigned URL for the image associated with the coupon.
     */
    const fetchMerchantImage = async (objectKey) => {
        try {
        const response = await axios.get(
            `${apiUrl}/GeneratePresignedUrl`,
            {
            params: { objectKey },
            }
        );
        setImageUrl(response.data); // Set the fetched image URL
        } catch (error) {
        console.error("Error fetching image:", error);
        setImageUrl(dummyimg); // Fallback in case of an error
        } finally {
        setIsLoading(false);
        }
    };

    useEffect(() => {
        if (type === 'Coupon') {
            // Trigger the image fetch on component mount
            fetchCouponImage("CouponImages/"+data.merchantID+"/"+data.imgName); 
        } else if (type === 'Merchant') {
            // Trigger the image fetch on component mount
            fetchMerchantImage("MerchantLogos/"+data.imgName);
        }
    }, [data, type]);

    const navigateToClaims = () =>{  
        navigate('/claim', {state: {data,imageUrl}});
      }

    const renderCouponView = () => (
        <div className="search-result-item">
            <div className="card">
                <Link to={`/${getCouponUrl(data.couponID)}`} style={{ textDecoration: "none", color: "inherit" }}>
                    <div className="">
                        <div className="">
                            <img src={imageUrl} className="result-image" height="144px" alt=""/>
                        </div>
                        <div className="">
                            <div className="card-inner">
                                <div className="merchant-name">
                                    <span>
                                        <img src={restaurent} alt="restaurant" className="restaurant-icon"/>
                                    </span>
                                    {data.merchantName}
                                </div>
                                <h5 className="result-item-name">{data.couponName}</h5>
                                <p className="result-rate-now" style={{ color: "#e80d0d" }}>
                                    ${data.offerPrice}
                                    <sup className="previous-price insto-p">
                                        <span className="in-sp-t">${data.inStorePrice}</span>
                                    </sup>
                                </p>
                                <div className="coupon-tags">
                                    <p className="you-save">
                                        <small className="text" style={{ color: "#007aff" }}>
                                            Save ${data.inStorePrice - data.offerPrice}
                                        </small>
                                    </p>
                                    <p className="other-tags">
                                        <small className="text">
                                            {data.couponCategory}
                                        </small>
                                    </p>
                                </div>
                                <div className="d-flex expiry-info">
                                    <p className="expiry-date">Expires on: {data.validTill}</p>
                                </div>
                                <div className="location">
                                    {data.distanceToMerchant !== "9999.99 KM" && (
                                        <>
                                            <span>
                                                <img src={location} alt="location"/>
                                            </span>{data.distanceToMerchant}
                                        </>
                                    )}
                                </div>   
                                <div className="add-to-wallet-btn" style={{ background: "#e80d0d", color: "#ffffff", borderColor: "#e80d0d" }}>Get Coupon</div>           
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );

    const renderMerchantView = () => (
        <div className="search-result-item">
            <div className="card">
                <Link to={`/${getMenuUrl(data.merchantID)}`} style={{ textDecoration: "none", color: "inherit" }}>
                    <div className="">
                        <div className="">
                            <img src={imageUrl} className="result-image" height="144px" alt=""/>
                        </div>
                        <div className="">
                            <div className="card-inner">
                                <div className="merchant-title">
                                    <h5 className="text-center result-merchant-name">{data.merchantName}</h5>
                                </div>
                                <div className="result-merchant-center">
                                    <div className="text-center offer-count">{data.merchantType}{bullet}
                                        {[...data.subType1, ...data.subType2].join(bullet)}</div>
                                </div>
                                <div className="text-center offer-count">{data.couponCount} Coupons Available</div>
                                <div className="merchant-location d-flex align-items-center justify-content-center">
                                    {data.distanceToMerchant !== "9999.99 KM" && (
                                        <>
                                            <span>
                                                <img src={location} alt="location"/>
                                            </span>{data.distanceToMerchant}
                                        </>
                                    )}
                                </div>
                                <div className="text-center">
                                    <Card.Link href={data.link} className='view-offer'>View More</Card.Link>       
                                </div>         
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );

     return (
        <> {type === 'Coupon' ? renderCouponView() : renderMerchantView()} </>
     )
}

export default SearchResultCard;
