import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { getCouponUrl } from "../utils/HelperFunctions";
// import '../styles/HorizonatlCoupens.css';
import dummyimg from "../components/images/default/couponFallback.png"
import locationIcon from "../components/images/location.svg"
import restaurent from "../components/images/rest-icon.svg"
const MerchantPageCoupons = ({ coupon }) => {
  // URL based on run environment
  const apiUrl = process.env.REACT_APP_apiUrl;

  const [imageUrl, setImageUrl] = useState(dummyimg); // Initial image as fallback
  const [isLoading, setIsLoading] = useState(true);

  /**
   * Fetches the presigned URL for the image associated with the coupon.
   */
  const fetchImage = async (objectKey) => {
      try {
      const response = await axios.get(
          `${apiUrl}/GeneratePresignedUrl`,
          {
          params: { objectKey },
          }
      );
      setImageUrl(response.data); // Set the fetched image URL
      } catch (error) {
      console.error("Error fetching image:", error);
      setImageUrl(dummyimg); // Fallback in case of an error
      } finally {
      setIsLoading(false);
      }
  };

  useEffect(() => {
      fetchImage("CouponImages/"+coupon.merchantID+"/"+coupon.imgName); // Trigger the image fetch on component mount
  }, [coupon.couponID]);

  return (
    <div className="row horizontal-coupens-div">
      <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
        <Link to={`/${getCouponUrl(coupon.couponID)}`} style={{ textDecoration: "none", color: "inherit" }}>
          <div className="card">
            <div>
              <div>
                <img src={imageUrl} className="coupen_image" height="144px" alt=""/>
              </div>
              <div>
                <div className="card-inner">
                  <div className="restaurant-name">
                  <span><img src={restaurent} alt="restaurent" className="rest-icon-img-new"/> </span>
                  {coupon.merchantName}</div>
                  <h5 className="card-title item-name">{coupon.couponName}</h5>
                  <p className="coupen-rate-now" style={{ color: "#e80d0d" }}>
                    Now {coupon.offerPrice}
                    <sup className="previous-prize h-previous">
                      In-Store <span className="in-sp-t"> {coupon.inStorePrice}</span>
                    </sup>
                  </p>
                  <div className="d-flex expiry-main">
                    <p className="you-save new-you-save">
                      <small className="text" style={{ color: "#007aff" }}>
                        *you save {coupon.inStorePrice - coupon.offerPrice} 
                      </small>
                    </p>
                    <p className="other-tags"><small className="text">{coupon.couponCategory}</small></p>
                    <p className="expiry-text">{coupon.validTill}</p>
                  </div>
                  <div className="location">
                  {coupon.distanceToMerchant !== "9999.99 KM" && (
                    <>
                      <span>
                        <img src={locationIcon} alt="location" />
                      </span>
                      {coupon.distanceToMerchant}
                    </>
                  )}
                  </div>
                  <div
                    className="claim-now"
                    style={{ background: "#e80d0d", color: "#ffffff", borderColor: "#e80d0d" }}
                  >
                    Add to Wallet
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>    
    </div>
   
  );
};

export default MerchantPageCoupons;
