import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from "../provider/authProvider";
import Slider from 'react-slick';

import WalletCardDeck from '../components/WalletCardDeck';
import SearchResultCard from '../components/SearchResultCard';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import "../styles/SearchResults.css";

const ViewAllCouponsPage = () => {

    const heading = "Coupons near you";

    const [errorMessage, setErrorMessage] = useState('');
    const location = useLocation();
    const { coupons } = location.state || { coupons: [] }; // Handle case where state might be undefined

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [coupons]);

    return (
        <div>
          <Container className="mx-auto wallet-details-wrap">
            <Row className="align-items-top mb-5">
                <Col className="p-0">
                    {errorMessage ? (
                        <Alert variant="danger">{errorMessage}</Alert>
                    ) : (
                        <>
                            {coupons.length === 0 ? (
                                <Alert variant="info">Sorry, there are no coupons near your location.</Alert>
                            ) : (
                                <WalletCardDeck heading={heading}>
                                    {coupons.map(coupon => (
                                        <SearchResultCard key={coupon.couponId} data={coupon} type="Coupon"/>
                                    ))}
                                </WalletCardDeck>
                            )}
                        </>
                    )}
                </Col>
            </Row>
          </Container>
        </div>
    );
};

export default ViewAllCouponsPage;
