import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import item1 from "../components/images/order1.svg";
import item2 from "../components/images/order2.svg";
import item3 from "../components/images/order3.svg";

import "../styles/HowitWorks.css";
const HowToClaim = () => {
  return (
    <div className="section-background mt-0">
      <Container className="d-flex justify-content-center">
        <Row className="">
          <Col md={4} className="d-flex flex-column align-items-center">
            <img src={item1} alt="Item 1" className="item-image" />
            <h2>Claim Coupon</h2>
            <p className="item-text">
              Click on 'Add to Wallet' to add any coupon to your wallet. Visit your wallet to see all coupons you have collected. 
              From here, you can claim any coupon you want,
              Make sure you are at the store before you press 'Claim'.
            </p>
          </Col>
          <Col md={4} className="d-flex flex-column align-items-center">
            <img src={item2} alt="Item 2" className="item-image" />
            <h2>Visit the counter</h2>
            <p className="item-text">
              Once you have claimed the coupon, show the claim details to the counter staff. 
              Remember, this page will only be available for limited time once you press 'claim now'.
            </p>
          </Col>
          <Col md={4} className="d-flex flex-column align-items-center">
            <img src={item3} alt="Item 3" className="item-image" />
            <h2>Get it done</h2>
            <p className="item-text">
              The store will reduce the 'You save' amount from your bill, Bag the Bargain!
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HowToClaim;
