import React, { useState, useEffect } from 'react';
import { Form, Button, Col, Container, Row, Alert, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from "../provider/authProvider"; 
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const getDeviceFingerprint = async () => {
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  const deviceId = result.visitorId;
  console.log("Device Fingerprint:", deviceId);
  return deviceId;
};

function LoginPage() {
  const apiUrl = process.env.REACT_APP_apiUrl;
  const location = useLocation();
  const navigate = useNavigate();
  const { setToken } = useAuth(); 

  const [formData, setFormData] = useState({
    email: '',
    deviceID: '', // Device ID will be populated using FingerprintJS
    otp: '' // Assuming OTP is part of the form data
  });

  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);
  const [isFailureMessageVisible, setIsFailureMessageVisible] = useState(false);
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showOtpModal, setShowOtpModal] = useState(false);

  useEffect(() => {
    // Generate device ID on component mount
    getDeviceFingerprint().then(deviceId => {
      setFormData(prevFormData => ({ ...prevFormData, deviceID: deviceId }));
    });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLogin = async (event) => {
    setErrorMessage(false);
    event.preventDefault();
  
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
  
    setValidated(true);
  
    const { email, deviceID, otp } = formData;
  
    const loginUrl = new URL(`${apiUrl}/CustomerLogin`);
    loginUrl.searchParams.append('email', email);
    loginUrl.searchParams.append('deviceID', deviceID);
    // Empty OTP, Required by service
    loginUrl.searchParams.append('otp', '');
  
    try {
      const response = await fetch(loginUrl.toString(), {
        method: 'GET',
      });
  
      const responseText = await response.text();
  
      if (response.ok) {
        setToken(email);
        console.log(responseText);
  
        setIsSuccessMessageVisible(true);
        setTimeout(() => {
          if (localStorage.getItem('afterLoginReturnTo')) {
            const returnTo = localStorage.getItem('afterLoginReturnTo');
            const cachedCoupon = JSON.parse(localStorage.getItem('cachedCoupon'));
            localStorage.removeItem('afterLoginReturnTo');
            localStorage.removeItem('cachedCoupon');
            navigate(returnTo, { state: { cachedCoupon } });
          } else {
            navigate('/');
          }
        }, 1000);
      } else if (response.status === 401) {
        setShowOtpModal(true);
      } else {
        setErrorMessage(responseText);
        setIsFailureMessageVisible(true);
        setTimeout(() => {
          navigate('/Signup');
        }, 2000);
      }
    } catch (error) {
      console.error('Error during login:', error);
      setErrorMessage('An unexpected error occurred. Please try again later.');
      setIsFailureMessageVisible(true);
    }
  };
  

  const handleOtpSubmit = async () => {
    const { email, deviceID, otp } = formData;

    const loginUrl = new URL(`${apiUrl}/CustomerLogin`);
    loginUrl.searchParams.append('email', email);
    loginUrl.searchParams.append('deviceID', deviceID);
    loginUrl.searchParams.append('otp', otp);

    try {
      const response = await fetch(loginUrl.toString(), {
        method: 'GET'
      });

      const responseText = await response.text();
      if (response.ok) {
        if (responseText === "Login Successful") {
          setToken(email);
          console.log('Login successful:', responseText);

          setIsSuccessMessageVisible(true);
          setShowOtpModal(false);
          setTimeout(() => {
            navigate('/'); 
          }, 1000);
        } else {
          setErrorMessage(responseText);
          setIsFailureMessageVisible(true);
        }
      } else {
        setErrorMessage(responseText);
        setIsFailureMessageVisible(true);
      }
    } catch (error) {
      console.error('Error during login:', error);
      setErrorMessage('An unexpected error occurred. Please try again later.');
      setIsFailureMessageVisible(true);
    }
  };

  return (
    <div className="vh-100 d-flex align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col className="shadow py-3 rounded-lg" xs={12} md={6}>
            <h2 className="text-center mb-4">What's your email?</h2>
            <Form noValidate validated={validated} onSubmit={handleLogin}>
              <Form.Group controlId="formBasicEmail" className="mb-3">
                <Form.Label>E-mail Address</Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="Enter Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  style={{ height: '50px', width: '100%' }}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid e-mail!  
                </Form.Control.Feedback>
              </Form.Group>

              {isSuccessMessageVisible && (
                <Alert variant="success">
                  Login successful! Redirecting to homepage...
                </Alert>
              )}

              {isFailureMessageVisible && (
                <Alert variant="danger">
                  {errorMessage || 'Invalid Email! Redirecting to sign-up...'}
                </Alert>
              )}

              <div className="text-center mt-5">
                <Button className="w-100 border-0" style={{backgroundColor: "#e80d0d"}} type="submit">
                  Continue
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>

      {/* OTP Modal */}
      <Modal show={showOtpModal} onHide={() => setShowOtpModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enter OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formBasicOtp" className="mb-3">
            <Form.Label>OTP</Form.Label>
            <Form.Control
              name="otp"
              type="text"
              placeholder="Enter OTP"
              value={formData.otp}
              onChange={handleChange}
              required
              style={{ height: '50px', width: '100%' }}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid OTP!  
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowOtpModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleOtpSubmit}>
            Submit OTP
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default LoginPage;
