import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Row, Col, Container, Tab, Tabs } from "react-bootstrap";
import { getMenuUrl } from "../utils/HelperFunctions";
import "../styles/MerchantMenuPage.css";
import dummyimg from "../components/images/default/merchantFallback.svg"
import location from "../components/images/location.svg";
import Footer from "../components/Footer";
import MerchantPageCoupons from "../components/MerchantPageCoupons";
////////////////pass coupons from other page
const MerchantMenuPage = ({ merchant, coupons }) => {
  // URL based on run environment
  const apiUrl = process.env.REACT_APP_apiUrl;

  const navItems = ["Coupons"];
  const headingId = getMenuUrl(navItems[0]);
  const [key, setKey] = useState("home");
  const [imageUrl, setImageUrl] = useState(dummyimg); // Initial image as fallback
  const [isLoading, setIsLoading] = useState(true);
  
  /**
   * Fetches the presigned URL for the image associated with the coupon.
   */
  const fetchImage = async (objectKey) => {
    try {
    const response = await axios.get(
        `${apiUrl}/GeneratePresignedUrl`,
        {
        params: { objectKey },
        }
    );
    setImageUrl(response.data); // Set the fetched image URL
    } catch (error) {
    console.error("Error fetching image:", error);
    setImageUrl(dummyimg); // Fallback in case of an error
    } finally {
    setIsLoading(false);
    }
  };

  useEffect(() => {
      fetchImage("MerchantLogos/"+merchant.imgName); // Trigger the image fetch on component mount
  }, [merchant.merchantID]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="restaurent-wrapper">
        <MerchantMenuHeader merchant={merchant} imageUrl={imageUrl} />
        <Container>
          <ScrollspyNavbar
            headingId={headingId}
            keyState={key}
            setKeyState={setKey}
            coupons={coupons}
            merchant={merchant}
          />
        </Container>
      </div>
      <Footer />
    </div>
  );
};

const CouponSection = ({ coupons, headingId, MerchantID }) => {
  return (
    <div id={headingId}>
      <h2 className="pt-4 my-0">Available Coupons</h2>
      {coupons.map(
          (coupon) =>
            coupon.merchantID === MerchantID && (
              <MerchantPageCoupons key={coupon.couponID} coupon={coupon} />
            )
        )}
    </div>
  );
};

const MerchantMenuHeader = ({ merchant, imageUrl }) => {
  const bullet = " \u2022 ";

  return (
    <div>
      <Container>
        <div className="menu-card">
          <Row className="align-items-center">
            <Col md={6} >
              <h1 className="mb-0">{merchant.merchantName}</h1>
              <Card.Text className="mb-3">
                {merchant.merchantType}
              </Card.Text>
              <div className="location card-location rest-location">
                  <span>
                    <img src={location} alt="location" className="img-location" />
                    {merchant.address}
                    {merchant.distanceToMerchant !== "9999.99 KM" && (
                      <>
                      {bullet}
                      {merchant.distanceToMerchant} away
                      </>
                    )}
                  </span>
          
              </div>
              
            </Col>
            {/*<Col md={{ span: 3, offset: 3 }} xs={{ span: 6, offset: 6}}>
                <div className="rest-logo">
                    <img src={imageUrl} alt="hotel-logo"/>
                </div>
            </Col>*/}
            <Col md={{ span: 3, offset: 3 }} xs={{ span: 6, order: 2 }}>
                <div className="rest-img">
                    <img src={imageUrl} alt="hotel-logo"/>
                </div>
            </Col>
            <Col md={{ span: 6, order: 2 }} xs={{ span: 6, order: 1 }}>
              <Card.Text className="mb-3">
                {[...merchant.subType1, ...merchant.subType2].join(bullet)}
              </Card.Text>
              <Card.Text>
                {merchant.serviceType}
              </Card.Text>
            </Col>
            {/*<Col md={3} xs={7}>
              <div className="rest-img">
                <img src={hotel} alt="hotel" />
                <div className="view-all-photos">View photos</div>
              </div>
            </Col>    to be used later*/}
          </Row>
        </div>
      </Container>
    </div>
  );
};

const ScrollspyNavbar = ({
  headingId,
  keyState,
  setKeyState,
  coupons,
  merchant,
}) => {
  return (
    <div className="tabbar-wrap">
      <Tabs
        id="controlled-tab-example"
        activeKey={keyState}
        onSelect={(k) => setKeyState(k)}
        className="mb-3"
      >
        <Tab  eventKey="home" title="Coupons">
        {/* Deals card is an another layout tried which is said by Anand. Uncomment and use if needed */}
          {/* <DealsCards></DealsCards> */}
          
          {/* <HorizontalCoupens coupons={coupons}/> */}
          

           <CouponSection
            coupons={coupons}
            headingId={headingId}
            MerchantID={merchant.merchantID}
          /> 
        </Tab>
        <Tab  eventKey="profile" title="About" className="about-details menu-card">
          <h2 className="about-us-des">About Us</h2>
          <div className="content-container">
            <input type="checkbox" id="toggle" className="toggle-checkbox" />
            <p className="content-text">
              {merchant.merchantDescription}
            </p>
            <label htmlFor="toggle" className="toggle-label"></label>
          </div>

          <hr></hr>

          <h3>Address</h3>
          <div className="location card-location rest-location">
            <span>
              <img src={location} alt="location" className="img-location" />
              {merchant.address}
            </span>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default MerchantMenuPage;
