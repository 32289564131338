import { useEffect, useCallback, useState, useRef } from 'react';

/**
 * Custom hook to handle user location updates.
 * @param {string} apiUrl - The base URL of the API.
 * @param {string} email - The user's email.
 * @param {Function} onLocationUpdate - Callback function to call on location update.
 * @returns {Object} - An object containing any error messages.
 */
const useLocation = (apiUrl, email, onLocationUpdate) => { 
  const [errorMessage, setErrorMessage] = useState("");
  const previousLocation = useRef(null); // Ref to store the previous location

  // Initialize previousLocation from localStorage
  useEffect(() => {
    const storedLocation = localStorage.getItem('previousLocation');
    if (storedLocation) {
      previousLocation.current = JSON.parse(storedLocation);
    }
  }, []);

  /**
   * Function to update the user's location on the server.
   * @param {string} email - The user's email.
   * @param {Object} location - The user's location with latitude and longitude.
   */
  const updateUserLocation = useCallback(async (email, location) => {
    const Url = new URL(`${apiUrl}/UpdateCoordinates`);
    Url.searchParams.append('email', email);
    Url.searchParams.append('latitude', location.latitude);
    Url.searchParams.append('longitude', location.longitude);

    try {
      const response = await fetch(Url.toString(), { method: 'POST' });
      const responseText = await response.text();

      if (!response.ok) {
        console.error('User location could not be updated:', responseText);
      }
    } catch (error) {
      console.error('User location update error:', error);
    }
  }, [apiUrl]);

  /**
   * Function to get the user's current location.
   * If the location has changed, it updates the location on the server.
   */
  const handleGetLocation = useCallback(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            const newLocation = { latitude, longitude };
            // Check if the location has changed
            if (!previousLocation.current ||
              previousLocation.current.latitude !== latitude ||
              previousLocation.current.longitude !== longitude) {
              previousLocation.current = newLocation;
              localStorage.setItem('previousLocation', JSON.stringify(newLocation)); // Update localStorage
              updateUserLocation(email, newLocation);
              onLocationUpdate(); // Call the callback function
            }
          },
          (error) => {
            // Detailed error handling
            switch (error.code) {
              case error.PERMISSION_DENIED:
                setErrorMessage('Location access denied by the user.');
                break;
              case error.POSITION_UNAVAILABLE:
                setErrorMessage('Location information is unavailable.');
                break;
              case error.TIMEOUT:
                setErrorMessage('The request to get user location timed out.');
                break;
              default:
                setErrorMessage('An unknown error occurred while retrieving location.');
                break;
            }
            console.error('Error getting location:', error.message);
          }, 
          { enableHighAccuracy: true } // Enable high accuracy mode
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
        setErrorMessage('Geolocation is not available on your device.');
      }
    };

    // Check and request permission
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted') {
          getLocation();
        } else if (result.state === 'prompt') {
          navigator.geolocation.getCurrentPosition(
            () => getLocation(),
            (error) => {
              if (error.code === error.PERMISSION_DENIED) {
                setErrorMessage('Location permission denied. Please allow location permission to proceed.');
              }
            }
          );
        } else if (result.state === 'denied') {
          setErrorMessage('Location permission denied. Please allow location permission to proceed.');
        }
      }).catch((error) => {
        console.error('Error checking permissions:', error);
        getLocation(); // Fallback to try getting location anyway
      });
    } else {
      getLocation(); // Fallback to try getting location directly
    }
  }, [email, updateUserLocation, onLocationUpdate]);

  return { handleGetLocation, errorMessage };
};

export default useLocation;
