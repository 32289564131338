import React, { useState, useEffect }  from "react";
import axios from 'axios';
import { Card, Row, Col, Container, Button, ListGroup, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import dummyimg from "../components/images/default/couponFallback.png";
import HowToClaim from "../components/HowToClaim";
import Footer from "../components/Footer";
import "../styles/CoupenDetails.css";
import location from "../components/images/location.svg";
import restaurent from "../components/images/rest-icon.svg";
import { useAuth } from "../provider/authProvider";


const CouponDetailsPage = ({ coupon }) => {

  //URL based on run env
  const apiUrl = process.env.REACT_APP_apiUrl;

  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const { token } = useAuth(); //to get email
  const email = token;

  const [imageUrl, setImageUrl] = useState(dummyimg); // Initial image as fallback
  const [isLoading, setIsLoading] = useState(true);

    /**
     * Fetches the presigned URL for the image associated with the coupon.
     */
    const fetchImage = async (objectKey) => {
        try {
        const response = await axios.get(
            `${apiUrl}/GeneratePresignedUrl`,
            {
            params: { objectKey },
            }
        );
        setImageUrl(response.data); // Set the fetched image URL
        } catch (error) {
        console.error("Error fetching image:", error);
        setImageUrl(dummyimg); // Fallback in case of an error
        } finally {
        setIsLoading(false);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchImage("CouponImages/"+coupon.merchantID+"/"+coupon.imgName); // Trigger the image fetch on component mount
    }, [coupon.couponID]);

  const addToWallet = async () => {
    try {
      if (!coupon) {
        console.error('No coupon to add to wallet');
        setAlertMessage({ type: 'danger', text: 'No coupon to add to wallet' });
        setShowModal(true);
        return;
      }
  
      if (!email) {
        const cachedCoupon = coupon;
        navigate('/Signup', {
          state: {
            returnTo: '/Login',
            afterLoginReturnTo: window.location.pathname,
            cachedCoupon,
          },
        });
        return;
      }
    
      // Construct the URL with query parameters for inStoreCouponID and email
      const url = new URL(`${apiUrl}/AddCouponToWallet`);
      url.searchParams.append('couponID', coupon.couponID);  // Use the correct field name from randomCoupon
      url.searchParams.append('email', email);
  
      // Perform the POST request
      const response = await axios.post(url.toString(), null, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
  
      if (response.status === 200) {
        setAlertMessage({ type: 'success', text: response.data || 'Coupon added to wallet successfully.' });
        setShowModal(true);
        navigate('/Wallet');
      } else {
        const errorMessage = response.data || 'Something went wrong';
        if (errorMessage === 'Please signup or login first.') {
          navigate('/Signup');
        } else {
          setAlertMessage({ type: 'danger', text: errorMessage });
          setShowModal(true);
        }
      }
    } catch (error) {
      console.error('Error adding to wallet:', error);
      setAlertMessage({ type: 'danger', text: 'An error occurred. Please try again later.' });
      setShowModal(true);
    }
  };

  const navigateToWallet = () => {
    addToWallet();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setAlertMessage(null);
  };

  return (
    <div>
      <Container className="mx-auto mb-3 coupen-details-wrap">
        <div className="coupen-details-inner-div mx-auto">
          <Modal show={showModal} onHide={handleCloseModal} centered>
            <Modal.Body className="text-center">
              <p style={{ color: alertMessage?.type === 'success' ? 'green' : 'red' }}>
                {alertMessage?.text}
              </p>
            </Modal.Body>
            <Modal.Footer className="justify-content-center border-0">
              <Button variant="primary" onClick={handleCloseModal}>
                OK
              </Button>
            </Modal.Footer>
          </Modal>
          <h1 className="c-detail-title">   {coupon.merchantName}</h1>
          {/* <Card className="image-card">
            <Row className="no-gutters">
              <Col md={4}>
                <Card.Img
                  className="img-fluid card-image"
                  src={dummyimg}
                  alt="Card image"
                />
              </Col>
              <Col md={8}>
                <Card.Body>
                  <h2>{coupon.merchantName}</h2>
                  <p>{coupon.itemName}</p>
                  <Button
                  className="border-0 px-3 claim-now-btn-new"
                  style={{
                    borderRadius: "16px",
                    backgroundColor: "#e80d0d",
                    color: "#ffffff",
                  }}
                  onClick={navigateToClaims}
                >
                  Claim
                </Button>
                  <Card.Text style={{ fontSize: "14px" }}>
                    {coupon.description}
                  </Card.Text>
                </Card.Body>
              </Col>
            </Row>
          </Card> */}

          {/* old Card */}
          <Card className="coupon-menu-item" style={{ borderWidth: "0.5px" }}>
            <Col className="md-4 h-50 px-0 py-0">
              <Card.Img
                className="w-100 coupen-image-name"
                variant="top"
                src={imageUrl}
              />
              {/* <div
                  className="rounded-pill mt-0 mb-1 w-50 text-center float-right font-weight-bold "
                  style={{
                    background: "#ffffff",
                    color: "#000000",
                    borderColor: "#ffffff",
                    fontSize: "18px",
                  }}
                >
                  {coupon.merchantName}
                </div> */}
              {/* <div className="restaurant-name new-rest-name  ">
                <span>
                  <img
                    src={restaurent}
                    alt="restaurent"
                    className="rest-icon-img-new"
                  />{" "}
                </span>
                {coupon.merchantName}
              </div> */}
              {/* <Card.ImgOverlay className="d-flex justify-content-center align-items-end">
              
              </Card.ImgOverlay> */}
            </Col>
            <Card.Body className="pl-0 pr-0">
              <Row className="ml-1">
                <Col md={11}>
                  <Card.Title
                    className="c-detail-title"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    {coupon.couponName}
                  </Card.Title>
                  <Card.Text style={{ fontSize: "14px" }}>
                    {coupon.couponDescription}
                  </Card.Text>
                </Col>
              </Row>
            </Card.Body>
            <ListGroup className="list-group-flush">
              <Row className="ml-1 my-auto">
                <Col className="mt-1" md={12}>
                  {/* <p
                    className="card-text mt-1 mb-0 coupen-rate-now"
                    style={{ color: "#e80d0d" }}
                  >
                  
                  </p>
                  <p className="card-text mt-0 mb-0">
                    <small className=" previous-prize">
                    
                    </small>
                  </p> */}

                  <p className="coupen-rate-now" style={{ color: "#e80d0d" }}>
                  NOW ${coupon.offerPrice}
                    <sup className="previous-prize insto-p">
                    In-Store <span className="in-sp-t">${coupon.inStorePrice}</span>
                    </sup>
                  </p>
                  <p className="card-text mt-2 mb-3">
                    <small
                      className="text you-save coupen-detail-save"
                      style={{ color: "#007aff", fontWeight: "600"  }}
                    >
                       You save ${coupon.inStorePrice - coupon.offerPrice}
                    </small>
                    <small
                      className="text other-tags coupen-detail-save"
                      style={{ fontWeight: "600"  }}
                    >
                       {coupon.couponCategory}
                    </small>
                  </p>
                    <div className="location-expire-wrap">
                    {coupon.distanceToMerchant !== "9999.99 KM" && (
                      <div className="location"><span><img src={location} alt="location" /> </span>{coupon.distanceToMerchant}</div>
                    )}
                  <p className="expiry-text">Valid till: {coupon.validTill}</p>
                    </div>
                  <Button
                    className="border-0 px-3"
                    style={{
                      borderRadius: "16px",
                      backgroundColor: "#e80d0d",
                      color: "#ffffff",
                      float: "inline-end",
                      marginRight: "15px",
                    }}
                    onClick={navigateToWallet}
                  >
                    Add to Wallet
                  </Button>
                </Col>
                <div></div>
                {/* <Col className="d-flex justify-content-center align-items-center">
             
                </Col> */}
              </Row>
            </ListGroup>
            <Card.Footer className="bg-white" style={{ fontSize: "14px" }}>
              {/* <Row className="ml-1 font-weight-bold">
                {coupon.distance} away
              </Row> */}
            </Card.Footer>
          </Card>
          {/* old card ends */}
        </div>
      </Container>
      <HowToClaim />
      <Footer />
    </div>
  );
};

export default CouponDetailsPage;
