import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import axios from "axios";
import { useNavigate, useLocation } from 'react-router-dom';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const getDeviceFingerprint = async () => {
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  const deviceID = result.visitorId;
  return deviceID;
};

function SignUpPage() {
  // URL based on run environment
  const apiUrl = process.env.REACT_APP_apiUrl;
  const location = useLocation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    otp: '',
    deviceID: '', // Device ID will be populated using FingerprintJS
    latitude: '',
    longitude: '',
  });

  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);
  const [isOTPSuccessMessageVisible, setIsOTPSuccessMessageVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [validated, setValidated] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleGetLocation = async () => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setFormData((prevFormData) => ({ ...prevFormData, latitude, longitude }));
            console.log('User location:', latitude, longitude);
          },
          (error) => {
            // Detailed error handling
            switch (error.code) {
              case error.PERMISSION_DENIED:
                setErrorMessage('Location access denied by the user.');
                break;
              case error.POSITION_UNAVAILABLE:
                setErrorMessage('Location information is unavailable.');
                break;
              case error.TIMEOUT:
                setErrorMessage('The request to get user location timed out.');
                break;
              default:
                setErrorMessage('An unknown error occurred while retrieving location.');
                break;
            }
            console.error('Error getting location:', error.message);
          }, 
          { enableHighAccuracy: true } // Enable high accuracy mode
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
        setErrorMessage('Geolocation is not available on your device.');
      }
    };

    // Check and request permission
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted') {
          getLocation();
        } else if (result.state === 'prompt') {
          navigator.geolocation.getCurrentPosition(
            () => getLocation(),
            (error) => {
              if (error.code === error.PERMISSION_DENIED) {
                setErrorMessage('Location permission denied. Please allow location permission to proceed.');
              }
            }
          );
        } else if (result.state === 'denied') {
          setErrorMessage('Location permission denied. Please allow location permission to proceed.');
        }
      }).catch((error) => {
        console.error('Error checking permissions:', error);
        getLocation(); // Fallback to try getting location anyway
      });
    } else {
      getLocation(); // Fallback to try getting location directly
    }
  };

  useEffect(() => {
    handleGetLocation();
    getDeviceFingerprint().then(deviceID => {
      setFormData(prevFormData => ({ ...prevFormData, deviceID: deviceID }));
    });
  }, []);

  const handleSubmit = async (e) => {
    setErrorMessage(false);
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else if (!formData.latitude || !formData.longitude) {
      // Check if latitude and longitude are set
      setErrorMessage('We need your location data to proceed. Please allow location access.');
      handleGetLocation(); // Request location access again
    } else {
      try {
        const response = await axios.post(`${apiUrl}/CustomerSignUp`, formData);

        if (response.status === 200) {
          console.log('Signup successful:', response.data);
          setIsSuccessMessageVisible(true);
          setTimeout(() => {
            if (location.state && location.state.returnTo) {
              localStorage.setItem('afterLoginReturnTo', location.state.afterLoginReturnTo);
              localStorage.setItem('cachedCoupon', JSON.stringify(location.state.cachedCoupon));
              navigate(location.state.returnTo);
            } else {
              navigate('/Login'); // Redirect to login page after 2 seconds
            }
          }, 2000);
        } else {
          setErrorMessage('Signup failed: ' + response.data);
        }
      } catch (error) {
        console.error(error.response.data);
        setErrorMessage(error.response.data);
      }
    }

    setValidated(true);
  };

  const handleGenerateOTP = async () => {
    setErrorMessage(false);
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      setErrorMessage('Please enter a valid email address to generate OTP.');
      return;
    }

    const OTPUrl = new URL(`${apiUrl}/GenerateOTP`);
    OTPUrl.searchParams.append('email', formData.email);

    try {
      const response = await fetch(OTPUrl.toString(), {
        method: 'POST',
      });

      const responseText = await response.text();

      if (response.ok) {
        console.log(responseText);
        setIsOTPSuccessMessageVisible(true); // Show success message
      } else {
        console.error(responseText);
        setErrorMessage(responseText);
      }
    } catch (error) {
      console.error(error.response.data);
      setErrorMessage(error.response.data);
    }
  };

  return (
    <div className="vh-100 d-flex align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={6}>
            <h2 className="text-center">Enter your details to Sign-up</h2>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicFirstName">
                <Form.Label className="mt-3">First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  First Name is required!
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicLastName">
                <Form.Label className="mt-3">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Last Name is required!
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label className="mt-3">Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  e-mail is required!
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicOTP">
                <Form.Label className="mt-3">Enter OTP</Form.Label>
                <Row className="align-items-center">
                  <Col xs={8}>
                    <Form.Control
                    type="text"
                    placeholder="Enter OTP"
                    name="otp"
                    value={formData.otp}
                    onChange={handleChange}
                    required
                    />
                    <Form.Control.Feedback type="invalid">
                      OTP is required!
                    </Form.Control.Feedback>
                  </Col>
                  <Col xs={4} className="mt-md-0">
                    <Button className="w-100 border-0" style={{ backgroundColor: "#e80d0d" }} onClick={handleGenerateOTP}>
                      Get OTP
                    </Button>
                  </Col>
                </Row>
              </Form.Group>

              {isOTPSuccessMessageVisible && (
                <Alert className="mt-1" variant="success">
                  OTP sent to: {formData.email}
                </Alert>
              )}

              <Form.Group controlId="formBasicPhoneNumber">
                <Form.Label className="mt-3">Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter Phone Number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Phone Number is required!
                </Form.Control.Feedback>
              </Form.Group>

              <Row className="justify-content-center mt-5">
                <Col xs={12} md={6}>
                  <Button className="w-100 border-0" style={{ backgroundColor: "#e80d0d" }} type="submit">
                    Sign Up
                  </Button>
                  {isSuccessMessageVisible && (
                    <Alert className="mt-2" variant="success">
                      Sign-up successful, redirecting to login...
                    </Alert>
                  )}
                </Col>
              </Row>
            </Form>
            {errorMessage && (
              <Alert className="mt-2" variant="danger">
                {errorMessage}
              </Alert>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SignUpPage;
